import React,{useState} from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from './pages/home';
import Services from './pages/services';
import AppHeader from './components/appHeader';
import Footer from './components/footer';
import ContactUs from './pages/contact-us';
import AboutUs from './pages/about-us';
import FAQ from './pages/faq';


const Router = () => {
    const [navbarBgColor, setNavbarBgColor] = useState(window.innerWidth > 768 ? 'transparent' : '#000000')

    return (
        <div onScroll={(event) => {
            if(window.innerWidth < 768) return;
            if (event.currentTarget.scrollTop > 284) setNavbarBgColor("#000000")
            if (event.currentTarget.scrollTop === 0) setNavbarBgColor('transparent')
        }} id='App-container' className="App-container" >
            <BrowserRouter>
                <AppHeader navbarBgColor={navbarBgColor} />
                <Routes>
                    <Route path="/" element={<Home />}>
                        {/* <Route path="services" element={<Services />} /> */}
                    </Route>
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    )
}

export default Router;
