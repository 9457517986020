import { useNavigate } from "react-router-dom";
import { Divider } from 'antd';

import { routeConfig, footerConfigs } from "../configs";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="footer-container">
            <div className="sub-container" >
                <img alt="Logo" className='footer-logo' onClick={() => { navigate('/') }} src={require('../assets/Landing_page/Logo.png')} />
                {window.innerWidth > 768 &&  <div className='header-midbody'>
                    {
                        routeConfig.map((val) => <h4 key={val.title} onClick={() => val?.onClick(navigate)} style={{ color: 'white', cursor: 'pointer', fontWeight : '400' }}>{val.title}</h4>)
                    }
                </div>}
            </div>
            <Divider type="horizontal" style={{margin : 0, backgroundColor : '#282737'}}/>
            <div className="sub-container">
                <div className='footer-content'>
                    {
                        footerConfigs.map((val) => <h4 key={val} className="footer-section-2-headings" onClick={() => { navigate('/services') }} >{val}</h4>)
                    }
                </div>
            </div>
        </div>
    )
}

export default Footer;