import ContactUsForm from "../components/Form/ContactUs";
import { primaryButtonColor } from "../configs";



const ContactUs = () => {

    return (
        <div>
            <div className="section-1-container">
                <h1 className="title" style={{ color: primaryButtonColor }}>Contact</h1>
                <h1 className="title" style={{marginLeft : 2.5}}>us</h1>
            </div>
            <ContactUsForm/>
        </div>
    )
}
export default ContactUs;