import ArrowIcon from '../../assets/Icons/arrow_icon.svg';
import { CollapseProps } from '../../models/componentsModels';

const Collapse = ({title, description, isActive, setActiveIndex, index} : CollapseProps) => {
    return (
        <div onClick={() => { if(!isActive) setActiveIndex(index);
            else setActiveIndex(-1)
        
        }} className={isActive ? "collapse-container collapse-open" : "collapse-container"}>
            <div className='collapse-text-container'>
                <h1 className="collapse-text">{title ?? 'How do I contact a support?'}</h1>
                <img className='collapse-arrow-icon' src={ArrowIcon} />
            </div>

            {
                <div className='collapse-description-container'>
                    <p className='collapse-description-text'>{description ?? 'You can contact support through email,Linkedin and facebook'}</p>
                </div>
            }
        </div>
    )
}

export default Collapse;