import {
    Form,
    Input,
    Checkbox,
    Button
} from 'antd';
import { useState } from 'react';
import { primaryButtonColor } from '../../configs';

type SizeType = Parameters<typeof Form>[0]['size'];


const ContactUsForm = () => {
    const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default');

    const onFormLayoutChange = ({ size }: { size: SizeType }) => {
        setComponentSize(size);
    };
    return (
        <div className='contactus-form'>
            <h1 className="contactus-form-title"style={{ color: '#04659a', fontWeight: '600' }}>Ready To Get Started?</h1>
            <Form
                name="complex-form"
                className='contactus-form-container'
                initialValues={{ size: componentSize }}
                onValuesChange={onFormLayoutChange}
                size={componentSize as SizeType}
                onFinish={(val) => { console.log('value--->', val) }}
            >
                <Form.Item style={{ width: '100%', rowGap: 20 }}>
                    <Input placeholder="How can we help?" />
                </Form.Item>
                <Form.Item>
                    <Input.Group compact>
                        <Form.Item noStyle>
                            <Input style={{ width: '48.5%', marginRight: '1.5%', textAlign: 'left' }} placeholder="Your Name" />
                        </Form.Item>
                        <Form.Item noStyle>
                            <Input style={{ width: '48.5%', textAlign: 'left', marginLeft: '1.5%' }} placeholder="Your Email" />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item>
                    <Input.Group compact>
                        <Form.Item noStyle>
                            <Input style={{ width: '48.5%', marginRight: '1.5%', textAlign: 'left' }} placeholder="(+971) 123-4567" />
                        </Form.Item>
                        <Form.Item noStyle>
                            <Input style={{ width: '48.5%', textAlign: 'left', marginLeft: '1.5%' }} placeholder="Organization" />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item>
                    <Input style={{ height: 100 }} placeholder="Project Description" />
                </Form.Item>
                <Form.Item >
                    <Checkbox style={{ width: '100%' }} onChange={() => { }}>Check here to subscribe for updates.</Checkbox>
                </Form.Item>
                <Button className='contactus-form-send-button'>Send</Button>
            </Form>
        </div>
    )
}

export default ContactUsForm;