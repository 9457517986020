import { useState } from "react";
import Collapse from "../components/Collapse";
import { FaqConfig } from "../configs";

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <div className="faq-container">
            <img alt="Faq-image" className='Faq-image' src={require('../assets/FAQ/FAQ_Banner.png')} />
            <div className="faq-main-body">
                <h1 className="contactus-form-title" style={{ color: '#04659a', fontWeight: '600' }}>Ready To Get Started?</h1>
                <div className="faq-body">
                    {

                        FaqConfig.map((val,index) => <Collapse index={index} isActive={activeIndex === index} setActiveIndex={setActiveIndex} {...val} />)
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQ;