import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Divider, Drawer } from 'antd';
import { primaryButtonColor, routeConfig } from '../../configs';
import { useGlobalStore } from '../../stores/golbalStore';



const HamburgerMenu = () => {
    const navigate = useNavigate();
    const [menuState, setMenuState] = useState(false);
    const globalMenuState = useGlobalStore((state : any) => state.drawerMenuState);
    const setGlobalMenuState = useGlobalStore((state : any) => state.setDrawerMenuState);

    useEffect(()=>{
        setMenuState(globalMenuState);
    },[globalMenuState])


    const showDrawer = () => {
        setGlobalMenuState(!menuState)
    };

    const onClose = () => {
        setMenuState(false);
    };

    return (
        <>
            <div id='hamburger' onClick={showDrawer} className={menuState ? 'hamburger openmenu' : 'hamburger'}>
                <span className='line-1' />
                <span className='line-2' />
                <span className='line-3' />
            </div>
            <Drawer
                placement="left"
                closable={false}
                onClose={onClose}
                open={menuState}
                style={{ backgroundColor: '#f8f8f8', width: '100%', height: '90vh' }}
                key="left"
            >
                {
                    routeConfig.map(val => (
                        <div key={val.title} className='menu-item'>
                            <a id={val.title} className="menu-item-text" onClick={() => {
                                val.onClick(navigate);
                                setGlobalMenuState(false)
                            }}>{val.title}</a>
                            <Divider type="horizontal" style={{ margin: 0, backgroundColor: '#efefef' }} />
                        </div>
                    ))
                }
                <Button onClick={() => {
                    navigate('contact-us')
                    setGlobalMenuState(false)
                }} style={{ backgroundColor: primaryButtonColor, width: '100%', alignSelf: 'center', marginTop: 20, height: 50 }} type="primary">Contact us</Button>
            </Drawer>
        </>
    )
}

export default HamburgerMenu;