import { Button } from 'antd';

import { primaryButtonColor, section4Config, section5Config, servicesConfig } from '../configs';
import CounterText from '../components/counterText';
import ServiceCard from '../components/Cards/service-card';
import { useNavigate } from 'react-router';

const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='home-banner' >
                <div className='landing-page-container'>
                    <h1 className='section-1-title'>
                        Business Processes <br /> With Our Automated<br />
                        <span style={{ color: primaryButtonColor, marginLeft: 0 }}>Services
                            <span className='section-1-subtitle'>&&nbsp;
                            </span>
                            Solutions
                        </span>
                    </h1>
                    <p className='section-1-description'>Boost your business with our customized solutions that cater to all your business needs.</p>
                    <Button className='get-started' onClick={() => navigate('contact-us')} style={{ backgroundColor: primaryButtonColor }} type="primary">Get Started</Button>
                    {/* <div style={{ display: 'flex', height: '24%', marginTop: '3%', columnGap: 15 }}>
                        <CounterText title={"Customers Happy"} count={"130k+"} />
                        <CounterText title={"Customers Happy"} count={"50k+"} />
                        <CounterText title={"Customers Happy"} count={"100+"} />
                    </div> */}
                </div>

            </div>
            <div className='section-2' style={{ height: '100vh' }}>
                <div className='section-2-container'>
                    <div className='section-2-subcontainer'>
                        <h2 className='section-2-heading' style={{ marginBottom: 0 }}>Your</h2>
                        <h1 className='section-2-heading' style={{ color: '#05669b', marginTop: 0 }}>Trusted Partner</h1>
                        <p className='section-2-description'>We offer the best IT consulting services, assisting in hiring technology experts for your business and projects of all over the world to meet your workforce requirements. Weather its an individual team or augmentation of dedicated development team for your company, we've got it covered. As a trusted IT consulting provider, we are always updating our workforce in order to provide unrivaled talent to fulfill our client needs and achieve their business goals.</p>
                        <Button className='get-started' onClick={() => navigate('contact-us')} style={{ backgroundColor: '#05669b', marginTop: '1%' }} type="primary">Get Started</Button>
                    </div>
                    <img className='trusted-partner-image' alt='Trusted Partner' loading='lazy' src={require('../assets/Landing_page/Trust_Partner.png')} />
                </div>
                <h2 className='section-2-bottom-heading' >Trusted By Fast-Growing <br /> Companies All Around The World </h2>
            </div>
            <div id='section-3' className='section-3'>
                <h1 className='section-3-heading'>Services We Offer</h1>
                <p className='section-3-paragraph'>We are a team of experts who are experienced in various fields such as Cyber Security, Mobile Development, Custom Software Development, UI/UX Designing, and SEO. Our team is ready to assist you in every stage of bringing your idea into reality. </p>
                <div className='section-3-body'>
                    {
                        servicesConfig.map((val) => <ServiceCard key={val.title} {...val} />)
                    }
                </div>
            </div>
            <div className='section-4'>
                {
                    section4Config.map(val => (
                        <div key={val.title} className='section-4-item'>
                            <img alt='Review Statistics' style={{ width: '50%', aspectRatio: 1, ...val.imageStyle }} src={val.image} />
                            <div style={{ display: 'flex', color: '#FFFFFF', alignItems: 'center', columnGap: 5, marginTop: 5 }}>
                                <h1 className='section-4-heading-1'>{val.count}<span className='section-4-heading-2'>{val.middleText}</span> </h1>
                                <h4 className='section-4-heading-3' style={val.titleStyle}>{val.title}</h4>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='section-5'>
                <h1 className='section-5-title'>Let's Solve Your Business<br />  Challenge Togather!</h1>
                <div className='section5-body'>
                    {
                        section5Config.map(val => (
                            <div key={val.title} className='section-5-item-container'>
                                <img alt={val.title} src={val.image} style={{ width: '20%', aspectRatio: 1, ...val.imageStyle }} />
                                {
                                    val.title.split('\n').map((value, index) => <h2 key={value} className='section-5-item-title' style={{ marginTop: index === 0 ? val.titleStyle?.marginTop ?? 20 : 0 }}>{value}</h2>)
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='section-8'>
                <h1 style={{ fontWeight: '500', color: '#FFFFFF' }}>Future Proof Your<br /> Business Today.</h1>
                <p className='section-8-paragraph'>Taking your business online expands your reach, offers convenience to customers, can be cost-effective, allows direct customer engagement, provides valuable data insights, and gives a competitive edge</p>
                <Button className='get-started' onClick={() => navigate('contact-us')} style={{ backgroundColor: '#FFFFFF', color: 'black', fontWeight: '500', marginRight: 0, marginTop: 15 }} type="primary">Get Started</Button>
            </div>
        </>
    )
}

export default Home;