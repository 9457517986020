import { Button } from "antd";
import { aboutUsSection3Config, aboutUsSection4Links, aboutusConfig, primaryButtonColor } from "../configs";
import { useNavigate } from "react-router";
import ServiceCard from "../components/Cards/service-card";
import ContactUsForm from "../components/Form/ContactUs";

const AboutUs = () => {
    const navigate = useNavigate();
    return (
        <div className="aboutus-container">
            <div className="section1-container">
                <h1 className="title">Experience Excellence With Us!</h1>
                <p className="description">Technovative is the go-to partner for numerous global enterprises,SMEs and tech innovators to maximixe their business value.</p>
                <Button className="contact-button" onClick={() => {
                    navigate('/contact-us')
                }} type="primary">Contact us</Button>
            </div>
            <div className="section2-container">
                <h2 className="title1">Problems</h2>
                <h1 className="title2">We'll Help You Solve</h1>
                <div className="cards-container">
                    {
                        aboutusConfig.map(val => <ServiceCard {...val} cardContainerStyle={{ maxHeight: '40vh' }} />)
                    }
                </div>
                <div className="vision-container">
                    <img style={{ height: '70%'}} loading="lazy" src={require('../assets/About_us/1715633881216.jpeg')} />
                    <div className="text">
                        <h1>Our Vision</h1>
                        <p>Our vision is to lead the way in digital transformation by providing innovative IT solutions that help businesses grow and adapt to future challenges. We are committed to delivering reliable, scalable technologies that improve efficiency and customer experiences. With a special focus on clients' custom requirements.</p>
                        <h3>Samha Ahmed</h3>
                    </div>
                </div>
            </div>
            <div className="section3-container">
                <h1 className="title">What Sets Us Apart?</h1>
                <div className="sub-container-1">
                    {
                        aboutUsSection3Config.map(val => (
                            <div className="item">
                                <div className="item-container">
                                    <h1 style={{ color: '#FFFFFF', margin: 0 }}>{val.count ?? '10'}</h1>
                                    <h1 className="sign" style={{ margin: 0 }}>{val.sign ?? '+'}</h1>
                                </div>
                                <div className="description-container">
                                    {
                                        val.description.split('\n').map(value => <p style={{ color: '#FFFFFF' }}>{value}</p>)
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="sub-container-2">
                    <h3 style={{ fontWeight: '400' }}>What works best for you?</h3>
                    <h3 style={{ textDecoration: 'underline', cursor: 'pointer' }}>Explore More</h3>
                </div>
            </div>
            <div className="section-4-container">
                <div className="subcontainer-1">
                    <ContactUsForm/>
                </div>
                <div className="subcontainer-2">
                    <h2>Quick Links</h2>
                    <div className="links-container">
                        {
                            aboutUsSection4Links.map(val => (
                                <div className="links-item">
                                    <img src={require(`../assets/About_us/${val.imageUrl}`)} className="item-icon" style={val.imageStyle}/>
                                    <p>{val.title}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;