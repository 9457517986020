import {useEffect} from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { routeConfig, primaryButtonColor } from '../configs';
import HamburgerMenu from './HamburgerMenu';
import { useGlobalStore } from '../stores/golbalStore';

const { Header } = Layout;

const AppHeader = ({navbarBgColor} : any) => {
    const navigate = useNavigate();
    const setMenuState = useGlobalStore((state : any) => state.setDrawerMenuState);

    function getScrollbarWidth() {
        // Create a div element with a defined width and height and hide it off-screen
        const div = document.createElement('div');
        div.style.width = '100px';
        div.style.height = '100px';
        div.style.overflow = 'scroll';
        div.style.position = 'absolute';
        div.style.top = '-9999px';
        document.body.appendChild(div);
      
        // Calculate the scrollbar width
        const scrollbarWidth = div.offsetWidth - div.clientWidth;
      
        // Remove the temporary div
        document.body.removeChild(div);
      
        return scrollbarWidth;
      }
    return (
        <Header className='header-container' style={{ animation: navbarBgColor == 'transparent' ? 'headerfadeout 0.2s' : 'headerfadein 1s', backgroundColor: navbarBgColor, width: window.innerWidth - getScrollbarWidth() }}>
            <img className='header-logo' onClick={() => {
                if(window.innerWidth < 768) setMenuState(false) 
                navigate('/') 
                }} src={require('../assets/Landing_page/Logo.png')} />
            {window.innerWidth > 768 ?
                <>
                    <div className='header-midbody'>
                        {
                            routeConfig.map((val) => <h4 onClick={() => val?.onClick(navigate)} style={{ color: 'white', cursor: 'pointer' }}>{val.title}</h4>)
                        }
                    </div>
                    <Button onClick={() => navigate('contact-us')} shape="round" style={{ backgroundColor: primaryButtonColor, right: '10vw', position: 'absolute' }} type="primary">Contact us</Button>
                </>
                : <HamburgerMenu/>
            }
        </Header>
    )
}
export default AppHeader;