import { useNavigate } from "react-router-dom";
import { CardProps } from "../../models/componentsModels";

const ServiceCard = ({
    title,
    description,
    action,
    cardIcon,
    iconStyle,
    cardContainerStyle
}: CardProps) => {
    const navigate = useNavigate()
    return (
        <div className="card-container" style={cardContainerStyle} >
            {cardIcon && <img className="service-card-icon" style={iconStyle} src={require(`../../assets/${cardIcon}`)} />}
            <h2 className="card-title">{title ?? 'Software Development'}</h2>
            {
                description && description.split('\n').map(val => <p className="card-description">{val ?? 'At cras diam at interdum. Sit diam fabicus lactus nissi tellus vestibulum pretium lacus vehicula.'}</p>)

            }
            {action && <div onClick={() => action(navigate)} className="card-seemore-button-container">
                <h4 className="card-seemore-button-title">See more</h4>
                <img className="card-seemore-button-arrow" src={require('../../assets/Icons/arrow.png')} />
            </div>}
        </div>
    )
}

export default ServiceCard;