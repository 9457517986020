import { useLocation } from "react-router-dom";
import ContactUsForm from "../components/Form/ContactUs";
import {serviceSection4Content } from "../configs";
import ServiceCard from "../components/Cards/service-card";
import { Button } from "antd";
import { useState } from "react";

const Services = () => {
    const { title,services } = useLocation().state;
    const [selectedService,setSelectedService] = useState(0)
    return (
        <div className="service-container" >
            <div className="section-1-container" style={{  }}>

            </div>
            <div className="section-3-container">
                <div className="sub-container">
                    <h1 className="services-title" style={{width : '70%'}}>{title}<span style={{ color: '#046599' }}> Services</span></h1>
                    <div className="development-process">
                        {
                            services.map((val : any,index : any)=>{
                                return(
                                    <Button className="service-btn" onClick={() => {setSelectedService(index) }} shape="round" style={{ backgroundColor: selectedService == index ? '#046599' : '#FFFFFF', color : selectedService == index ? '#FFFFFF' : '#046599'}} type="primary">{val.title}</Button>
                                )
                            })
                        }
                        {/* <Button onClick={() => { }} shape="round" style={{ backgroundColor: '#046599', width: '25vw', height: '8vh' }} type="primary">Contact us</Button>
                        <Button onClick={() => { }} shape="round" style={{ backgroundColor: '#046599', width: '25vw', height: '8vh' }} type="primary">Contact us</Button>
                        <Button onClick={() => { }} shape="round" style={{ backgroundColor: '#046599', width: '25vw', height: '8vh' }} type="primary">Contact us</Button>
                        <Button onClick={() => { }} shape="round" style={{ backgroundColor: '#046599', width: '25vw', height: '8vh' }} type="primary">Contact us</Button>
                        <Button onClick={() => { }} shape="round" style={{ backgroundColor: '#046599', width: '25vw', height: '8vh' }} type="primary">Contact us</Button>
                        <Button onClick={() => { }} shape="round" style={{ backgroundColor: '#046599', width: '25vw', height: '8vh' }} type="primary">Contact us</Button> */}
                        
                    </div>

                </div>
                <div className="sub-container-2" style={{ }}>
                    <ServiceCard cardContainerStyle={{ height: '85%', maxHeight : '90%',width : '85%',marginTop : '10%', backgroudColor : '#FFFFFF', paddingBlock : 20 }} title={services[selectedService].title} description={services[selectedService].description ??'Fast track your project delivery using our three-step Fast track your project delivery using our three-step'} />
                </div>
            </div>
            <div className="section-4-container">
                <h1 className="title">Our <span style={{ color: '#046599' }}>Process</span></h1>
                <h4>Fast track your project delivery using our three-step<br />application delivery process.</h4>
                <div className="service-card-container">
                    {
                        serviceSection4Content.map(val => <ServiceCard cardContainerStyle={{ backgroundColor: '#f0f9fe', maxHeight: '40vh', paddingBlock: 30 }} {...val} />)
                    }
                </div>

            </div>
            <ContactUsForm />
        </div>
    )
}

export default Services;