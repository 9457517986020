import Services from "../pages/services";
import { scrollTo } from "../utils";

export const routeConfig = [
    {
        title: 'Services',
        component: <Services />,
        onClick: (navigate: any) => {
            scrollTo(navigate);
        },
    },
    {
        title: 'About us',
        component: <Services />,
        onClick: (navigate: any) => { navigate('./about-us') }
    },
    {
        title: 'FAQs',
        component: <Services />,
        onClick: (navigate: any) => { navigate('./faq') }
    },
    // {
    //     title : 'Testimonials',
    //     component : <Services/>,
    //     onClick : (navigate : any) => {navigate('./services')}
    // },
]

export const footerConfigs = [
    '©2023 Technovative. All rights reserved',
    'Terms & Conditions',
    'Privacy policy'
]

export const servicesConfig = [
    {
        title: 'Web App Development',
        description: 'We build modern, secure and scalable web applications to simplify business processes for you',
        action: (navigate: any) => {
            navigate('/Services', {
                state: {
                    title: 'Web App Development',
                    services: [
                        {
                            title: 'Frontend Development',
                            description: 'We offer full-stack front-end expertise to create innovative, human-centric and goal-oriented web & mobile solutions.'
                        },
                        {
                            title: 'Backend Development',
                            description: 'We build back-end solutions that are extensible, scalable, and easy to maintain.'
                        },
                        {
                            title: 'Experience Design',
                            description: "We're experts in UI/UX design and front-end development, making user experiences smooth."
                        },
                        {
                            title: 'Full Stack Development',
                            description: 'We offer comprehensive software engineering services for backend solutions, catering to both simple and high-performance needs.'
                        },
                        {
                            title: 'Ecommerece Web Development',
                            description: 'Technovative can build web apps for e-commerce businesses, including online stores, shopping carts, and payment gateway integration.'
                        },
                        {
                            title: 'Web App Maintainence And Updates',
                            description: 'Following the app launch, we offer ongoing maintenance and updates, addressing bug fixes, enhancing performance, and introducing new features as needed.'
                        },
                    ],
                }
            })
        }
    },
    {
        title: 'Mobile Application Development',
        description: "We don't just make apps, we create experiences. Our fast and seamless mobile app development process helps you stay ahead easily!",
        action: (navigate: any) => {
            navigate('/Services', {
                state: {
                    title: 'Mobile Application Development',
                    services: [
                        {
                            title: 'Native Mobile App Development',
                            description: 'Our native mobile app developers can build apps that fit all your needs'
                        },
                        {
                            title: 'Hybrid Mobile App Development',
                            description: 'We help you develop apps that are fully functional on different devices, leveraging a unique blend of technologies'
                        },
                        {
                            title: 'Wearables and Embedded software',
                            description: 'We can create companion apps for a number of wearable devices, integrate with smart devices.'
                        },
                    ],
                }
            })
        }
    },
    {
        title: 'Custom Software Development',
        description: 'Our full-stack engineering experts develop enterprise-grade solutions tailored to your specific business needs',
        action: (navigate: any) => {
            navigate('/Services', {
                state: {
                    title: 'Custom Software Development',
                    services: [
                       
                        {
                            title: 'Web App Development',
                            description: 'We specialize in creating interactive and user-friendly web applications designed to accommodate millions of users.'
                        },
                        {
                            title: 'Mobile App Development',
                            description: 'Our team has the flexibility to adapt to any industry and is skilled in creating world-class mobile apps that can be synchronized across multiple platforms.'
                        },
                    ]
                }
            })
        }
    },
    {
        title: 'UI/UX Designing',
        description: 'Looking to create a standout product with immediate success? We specialize in transforming meaningful experiences into tangible business results',
        action: (navigate: any) => {
            navigate('/Services', {
                state: {
                    title: 'UI/UX Designing',
                    services: [
                        {
                            title: 'User Research',
                            description: 'We conduct research on target users to understand their needs, behaviors, and preferences.'
                        },
                        {
                            title: 'Interface Design',
                            description: 'We design the visual components of a product and create wireframes and mockups.'
                        },
                        {
                            title: 'Interaction Design',
                            description: 'We design the interactions and user flows within a product, such as navigation, buttons, and gestures.'
                        },
                        {
                            title: 'Usability Testing',
                            description: 'We test a product with users to see how easily they can complete tasks and identify any issues or areas needing enhancement.'
                        },
                        {
                            title: 'Prototyping',
                            description: 'We create a working model of the product, which can be used for testing and demonstration purposes.'
                        },
                        {
                            title: 'Design System',
                            description: 'We create a set of design guidelines and components that can be reused across multiple projects, to ensure consistency and efficiency.'
                        },
                    ],
                }
            })
        }
    },
    {
        title: 'Devops',
        description: "We'll accelerate your deployment, streamline updates and upgrades, and assist you in achieving guaranteed higher uptime all at minimal cost",
        action: (navigate: any) => {
            navigate('/Services', {
                state: {
                    title: 'Devops',
                    services: [
                        {
                            title: 'Infrastructure Automation',
                            description: 'Optimize your infrastructure management by Utilize our code-based service to streamline provisioning and deployment, effectively managing your resources.'
                        },
                        {
                            title: 'CI/CD Development',
                            description: 'Automate the building, testing, and deployment of software, using tools likeJenkins, Travis CI, and CircleCI.'
                        },
                        {
                            title: 'Containerization And Orchestraization',
                            description: 'Package software in containers and manage those containers using orchestration tools like Docker and Kubernetes.'
                        },
                        {
                            title: 'Monitoring and Logging',
                            description: 'Monitor the performance and health of your applications and infrastructure, and to collect and analyze log data to troubleshoot issues.'
                        },
                        {
                            title: 'Cloud Infrastructure Management',
                            description: 'Manage and optimize the use of cloud-based infrastructure, such as Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform (GCP).'
                        },
                        {
                            title: 'Security & Compliance',
                            description:  'Ensure that your software development and deployment processes comply with security and regulatory requirements.'
                        },
                    ],
                }
            })
        }
    },
    {
        title: 'Q/A Testing',
        description: 'Minimize troubleshooting time and maximize product development for user-friendly experiences!',
        action: (navigate: any) => {
            navigate('/Services', {
                state: {
                    title: 'Q/A Testing',
                    services: [
                        {
                            title: 'Functional Testing',
                            description: 'We conduct research on target users to understand their needs, behaviors, and preferences.'
                        },
                        {
                            title: 'Non-Functional Testing',
                            description: 'We design the visual components of a product and create wireframes and mockups.'
                        },
                        {
                            title: 'Unit Testing',
                            description: 'We design the interactions and user flows within a product, such as navigation, buttons, and gestures.'
                        },
                        {
                            title: 'Integration Testing',
                            description: 'We test a product with users to see how easily they can complete tasks and identify any issues or areas needing enhancement.'
                        },
                        {
                            title: 'System Testing',
                            description: 'We create a working model of the product, which can be used for testing and demonstration purposes.'
                        },
                        {
                            title: 'Acceptance Testing',
                            description: 'We create a set of design guidelines and components that can be reused across multiple projects, to ensure consistency and efficiency.'
                        },
                        {
                            title: 'Regression Testing',
                            description: 'We create a set of design guidelines and components that can be reused across multiple projects, to ensure consistency and efficiency.'
                        },
                        {
                            title: 'Performance Testing',
                            description: 'We create a set of design guidelines and components that can be reused across multiple projects, to ensure consistency and efficiency.'
                        },
                        {
                            title: 'Security Testing',
                            description: 'We create a set of design guidelines and components that can be reused across multiple projects, to ensure consistency and efficiency.'
                        },
                        {
                            title: 'User Acceptance Testing (UAT)',
                            description: 'We create a set of design guidelines and components that can be reused across multiple projects, to ensure consistency and efficiency.'
                        },

                    ],
                }
            })
        }
    }
]

export const section4Config = [
    {
        count: '05',
        middleText: '+',
        title: 'Happy Clients',
        image: require('../assets/Landing_page/Happy_Clients.png')
    },
    {
        count: '04',
        middleText: '+',
        title: 'Years in Business',
        image: require('../assets/Landing_page/Years_in_business.png'),
        titleStyle: { width: 70 },
        imageStyle: { aspectRatio: 0.75, width: '37.5%' }
    },
    {
        count: '10',
        middleText: '+',
        title: 'Team Members',
        image: require('../assets/Landing_page/Team_members.png')
    },
    {
        count: '4.9/',
        middleText: '5',
        title: 'Client Feedback',
        image: require('../assets/Landing_page/Client_feedback.png')
    }
]

export const section5Config = [
    {
        title: 'Digital\nIdentity Managment',
        image: require('../assets/Landing_page/Digital_Identity.png')
    },
    {
        title: `Dynamic\nSupply Chain`,
        image: require('../assets/Landing_page/Dynamic_Supply_chain.png'),
        titleStyle: { width: 60 },
    },
    {
        title: 'Financial\nVisibility',
        image: require('../assets/Landing_page/Financial.png'),
        imageStyle: { aspectRatio: 1.25 },
        titleStyle: { marginTop: 33 }
    },
    {
        title: 'Transparency',
        image: require('../assets/Landing_page/Transparency.png'),
        imageStyle: { aspectRatio: 1.25 },
        titleStyle: { marginTop: 33 }

    },
    {
        title: 'Operation\nResilience',
        image: require('../assets/Landing_page/Operational.png')
    },
    {
        title: 'Sustainability',
        image: require('../assets/Landing_page/Sustainability.png')
    }
]

export const FaqConfig = [
    {
        title: 'How do I contact a support?',
        description: 'You can contact support through email, Linkedin and facebook.'
    },
    {
        title: 'What are your pricing models?',
        description: 'Our pricing models depend on the service. We offer both fixed-price contracts for specific projects and flexible pricing for ongoing managed services, which can be customized to suit your needs.'
    },
    {
        title: 'How do I contact a support?',
        description: 'You can contact support through email,Linkedin and facebook.'
    },
    {
        title: 'How do I contact a support?',
        description: 'You can contact support through email,Linkedin and facebook.'
    },
]

export const aboutusConfig = [
    {
        title: 'Accelarate digital transformation',
        description: 'We can help you meet evolving requirements by leveraging agile and innovative technologies scaleable and tailored to your needs.',
        cardIcon: 'About_us/accelerate.png',
        iconStyle: { width: '50px' }
    },
    {
        title: 'Build your dream team',
        description: 'With a team of 50+ software experts. We can customize our core services to meet your business needs.',
        cardIcon: 'About_us/dream-team.png',
        iconStyle: { width: '50px' }
    },
    {
        title: 'Optimize your digital ecosystem',
        description: 'By optimizing and unifying your digital ecosystem. We can help you facilitate ongoing growth and ensure long-term scalability.',
        cardIcon: 'About_us/Group_6442.png',
        iconStyle: { width: '35px' }
    }
]

export const aboutUsSection3Config = [
    {
        count: '10',
        sign: '+',
        description: 'Years Industry Experience'
    },
    {
        count: '50',
        sign: '+',
        description: 'Certified\nEngineers'
    }, {
        count: '20',
        sign: '+',
        description: 'Projects\nDelivered'
    }
]

export const aboutUsSection4Links = [
    {
        title: 'Products',
        imageUrl: 'Products.png',
        imageStyle: { width: '17.5%', aspectRatio: 1.6 }
    },
    {
        title: 'Careers',
        imageUrl: 'Careers.png'
    },
    {
        title: 'Events',
        imageUrl: 'Events.png'
    },
    {
        title: 'Resources',
        imageUrl: 'Resources.png'
    },
    {
        title: 'Investor Relations',
        imageUrl: 'Investors.png'
    },
    {
        title: 'Office Locations',
        imageUrl: 'Office_Locations.png'
    },
    {
        title: 'Accounts Payable',
        imageUrl: 'Accounts_Payable.png'
    },
    {
        title: 'Media Contacts',
        imageUrl: 'Media_Contacts.png'
    }
]

export const serviceSection4Content = [
    {
        title: 'Requirement gathering',
        cardIcon: 'Service_page/Requirement-gathering.svg',
        description: 'Requirements Definition\nAnalyzing the requirements\nDocumenting the process\nProvide initial design'
    },
    {
        title: 'Development & Testing',
        cardIcon: 'Service_page/Web-App-Testing.svg',
        description: 'Frontend & backend development\nClient Feedback\nQA Testing\nDeployments'
    },
    {
        title: 'Support & Maintainence',
        cardIcon: 'Service_page/Support-Maintenance.svg',
        description: 'SLA Based Support\nL3 and production support service\nOperational support when needed\nOn-going support'
    }
]

export const primaryButtonColor = '#0390d7';